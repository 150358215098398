import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import GroupStudentCell from './GroupStudentCell';
import AddSupervisorModal from './AddSupervisorModal';
import styles from "./SupervisorList.module.css"
import axios from 'axios';
import SupervisorCell from './SupervisorCell';

const SupervisorList = (props) => {
    const navigate = useNavigate()
    const URL = "https://api.wisdomlsi.com/api/v1/admin/supervisor_list/"

    const [isAddSupervisorModalShow, setIsAddSupervisorModalShow] = useState(false);
    const [supervisorList, setSupervisorList] = useState([]);

	useEffect(() => {
        fetchSupervisorList();
	}, []);

    function fetchSupervisorList() {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setSupervisorList(response.data["supervisor"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function selectSupervisor(supervisor) {
        props.tabAction(supervisor);
    }

	return (
        <div className={styles.container}>
            <div className={styles.top_menu}>
                <p className={styles.num_supervisor}>슈퍼바이저 {supervisorList && supervisorList.length}명</p>
                <p className={styles.add_supervisor} onClick={()=>setIsAddSupervisorModalShow(true)}>추가하기</p>
            </div>
            <div>
                {supervisorList && supervisorList.map((supervisor) => (
                    <SupervisorCell key={supervisor.supervisor.id} supervisor={supervisor} selectAction={selectSupervisor} />
                ))}
            </div>
            { isAddSupervisorModalShow ? <AddSupervisorModal refreshAction={fetchSupervisorList} modalAction={setIsAddSupervisorModalShow} /> : null }
        </div>
	);
};

export default SupervisorList;