import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./ConnectStudentModal.module.css"
import axios from 'axios';
import StudentConnectCell from './StudentConnectCell';
import GroupCell from './GroupCell';

let studentIdList = [];

const ConnectStudentModal = (props) => {
    const GROUP_URL = "https://api.wisdomlsi.com/api/v1/admin/group_list/"
    const STUDENT_URL = "https://api.wisdomlsi.com/api/v1/admin/group_student_list/";
    const CONNECT_URL = "https://api.wisdomlsi.com/api/v1/admin/connect_expert_student/";

    const [selectedGroup, setSelectedGroup] = useState(null);

    const navigate = useNavigate();
    const [groupList, setGroupList] = useState([]);
    const [studentList, setStudentList] = useState([]);

	useEffect(() => {
        fetchGroupList();
	}, []);

    function fetchGroupList() {
		axios({
			method: 'get',
			url: GROUP_URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setGroupList(response.data["data"]["active_group_list"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function fetchStudentList(group) {
        const params = {
            "group_id": group.info.id
        }

		axios({
			method: 'get',
			url: STUDENT_URL,
            params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setStudentList(response.data["student_list"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function connectStudent() {
        if (studentIdList.length === 0) {
            alert("연결하실 회원을 선택해주세요.");
        } else {
            const param = {
                "student_id_list": studentIdList,
                "expert_id": props.expert.expert.id
            }

            axios({
                method: 'post',
                url: CONNECT_URL,
                data: JSON.stringify(param),
                headers: {
				    "Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                    "Content-Type" : "application/json; charset=utf-8"
                }
            })
            .then(function(response) {
                if (response.data["code"] === "success") {
                    studentIdList = [];
                    props.refreshAction();
                    props.modalControl();
                }
            })
            .catch(function(error) {
                console.log("");
                console.log("ERROR : " + JSON.stringify(error));
                console.log("");

                // if (error.response.status === 401) {
                //     alert("");
                //     navigate("/");
                // }
            });
        }
    }

    function selectStudent(student) {
        let isSelected = false;
        let selectedIndex = -1;

        for (let i=0; i<studentIdList.length; i++) {
            if (studentIdList[i] === student.student.id) {
                isSelected = true;
                selectedIndex = i;
                break;
            }
        }

        if (isSelected) {
            studentIdList.splice(selectedIndex, 1);
        } else {
            studentIdList.push(student.student.id);
        }
    }

    function cancel() {
        studentIdList = [];
        props.modalControl();
    }

    const selectGroup = (group) => {
        setSelectedGroup(group);
        fetchStudentList(group);
    }

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>{props.expert.info.name} 전문가님과<br/>연결할 회원을 선택해주세요</p>
				<p className={styles.desc}>그룹을 먼저 선택하고 학생을 선택해주세요</p>
                <div className={styles.scroll}>
                    { selectedGroup === null && groupList && groupList.map((group) => (
                        <GroupCell key={group.id} group={group} selectAction={(group) => selectGroup(group)} />
                    ))}
                    { selectGroup !== null && studentList && studentList.map((student) => (
                        <StudentConnectCell key={student.student.id} student={student} selectAction={selectStudent}/>
                    ))}
                </div>
                <div className={styles.btn_group}>
                    <button className={styles.connect_btn} onClick={connectStudent}>연결하기</button>
                    <p className={styles.space}></p>
                    <button className={styles.cancel_btn} onClick={cancel}>취소</button>
                </div>
			</div>
		</div>
	);
};

export default ConnectStudentModal;