import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./Home.module.css"
import axios from 'axios';
import GroupList from './GroupList';
import Header from './Header';
import GroupsIcon from '@mui/icons-material/Groups';
import ArticleIcon from '@mui/icons-material/Article';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import CallIcon from '@mui/icons-material/Call';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ConsultingPref from './ConsultingPref';
import ExpertList from './ExpertList';
import Expert from './Expert';
import Supervisor from './Supervisor';
import SupervisorList from './SupervisorList';
import CouponPref from './CouponPref';

let selectedExpert = null;
let selectedSupervisor = null;

const Home = (props) => {
    // const URL = "https://api.wisdomlsi.com/api/v1/login/";
    const PORT_ONE_URL = "https://portone.io/korea/";

    const [selectedIndex, setSelectedIndex] = useState(0);

    // const navigate = useNavigate();

    // const emailRef = useRef(null);
    // const passwordRef = useRef(null);

    // function logIn() {
    //     if (!validate()) { return; }

    //     const param = {
    //         "email": emailRef.current.value,
    //         "password": passwordRef.current.value
    //     }

    //     axios({
    //         method: 'post',
    //         url: URL,
    //         data: JSON.stringify(param),
    //         headers: {
    //             "Content-Type": "application/json; charset=utf-8"
    //         }
    //     })
    //     .then(function(response) {
    //         let code = response.data["code"];

    //         if (code === "error") {
    //             alert("계정정보가 일치하지 않습니다.");
    //         } else {
    //             const data = response.data["data"];
    //             console.log(data);

    //             const accessToken = data["token"]["access"];
    //             const refreshToken = data["token"]["refresh"];

    //             window.localStorage.setItem("wisdom_access", accessToken);
    //             window.localStorage.setItem("wisdom_refresh", refreshToken);
    //         }
    //     })
    //     .catch(function(error) {
    //         console.log(error);
    //     });
    // }

    function selectExpert(expert) {
        selectedExpert = expert;
        setSelectedIndex(100);
    }

    function selectSupervisor(supervisor) {
        selectedSupervisor = supervisor;
        setSelectedIndex(200);
    }

    function openPortOne() {
        window.open([PORT_ONE_URL], '_blanck');
    }

	return (
        <div>
            <Header />
            <div className={styles.container}>
                <div className={styles.side_nav}>
                    <div className={styles.tab} onClick={() => setSelectedIndex(0)}>
                        { selectedIndex === 0 ?
                            <div className={styles.active_back}>
                                <GroupsIcon className={styles.active_icon} />
                                <p className={styles.tab_active_title}>그룹 목록</p>
                            </div> :
                            <div className={styles.deactive_back}>
                                <GroupsIcon className={styles.deactive_icon} />
                                <p className={styles.tab_deactive_title}>그룹 목록</p>
                            </div>
                        }
                    </div>
                    <div className={styles.tab} onClick={() => setSelectedIndex(1)}>
                        { selectedIndex === 1 || selectedIndex === 100 ?
                            <div className={styles.active_back}>
                                <ArticleIcon className={styles.active_icon} />
                                <p className={styles.tab_active_title}>전문가 목록</p>
                            </div> :
                            <div className={styles.deactive_back}>
                                <ArticleIcon className={styles.deactive_icon} />
                                <p className={styles.tab_deactive_title}>전문가 목록</p>
                            </div>
                        }
                    </div>
                    <div className={styles.tab} onClick={() => setSelectedIndex(2)}>
                        { selectedIndex === 2 || selectedIndex === 200 ?
                            <div className={styles.active_back}>
                                <FindInPageIcon className={styles.active_icon} />
                                <p className={styles.tab_active_title}>슈퍼바이저 목록</p>
                            </div> :
                            <div className={styles.deactive_back}>
                                <FindInPageIcon className={styles.deactive_icon} />
                                <p className={styles.tab_deactive_title}>슈퍼바이저 목록</p>
                            </div>
                        }
                    </div>
                    <div className={styles.tab} onClick={() => setSelectedIndex(3)}>
                        { selectedIndex === 3 ?
                            <div className={styles.active_back}>
                                <ConfirmationNumberIcon className={styles.active_icon} />
                                <p className={styles.tab_active_title}>쿠폰 관리</p>
                            </div> :
                            <div className={styles.deactive_back}>
                                <ConfirmationNumberIcon className={styles.deactive_icon} />
                                <p className={styles.tab_deactive_title}>쿠폰 관리</p>
                            </div>
                        }
                    </div>
                    <div className={styles.tab} onClick={() => setSelectedIndex(4)}>
                        { selectedIndex === 4 ?
                            <div className={styles.active_back}>
                                <CallIcon className={styles.active_icon} />
                                <p className={styles.tab_active_title}>상담요청 관리</p>
                            </div> :
                            <div className={styles.deactive_back}>
                                <CallIcon className={styles.deactive_icon} />
                                <p className={styles.tab_deactive_title}>상담요청 관리</p>
                            </div>
                        }
                    </div>
                    <div className={styles.tab} onClick={openPortOne}>
                        <div className={styles.deactive_back}>
                            <CreditCardIcon className={styles.deactive_icon} />
                            <p className={styles.tab_deactive_title}>결제 관리</p>
                        </div>
                    </div>
                </div>
                <div className={styles.content_container}>
                    { selectedIndex === 0 ? <GroupList /> : null }
                    { selectedIndex === 1 ? <ExpertList tabAction={selectExpert} /> : null }
                    { selectedIndex === 2 ? <SupervisorList tabAction={selectSupervisor} /> : null }
                    { selectedIndex === 3 ? <CouponPref /> : null }
                    { selectedIndex === 4 ? <ConsultingPref /> : null }
                    { selectedIndex === 100 ? <Expert expert={selectedExpert} tabAction={setSelectedIndex} /> : null }
                    { selectedIndex === 200 ? <Supervisor supervisor={selectedSupervisor} tabAction={setSelectedIndex} /> : null }
                    {/* { selectedIndex === 1 ? <GroupTeacherList indexAction={setSelectedIndex} teacherAction={setSelectedTeacher} /> : null }
                    { selectedIndex === 2 ? <GroupPref /> : null }
                    { selectedIndex === 3 ? <GroupTeacherStudentList indexAction={setSelectedIndex} teacher={selectedTeacher} /> : null } */}
                </div>
            </div>
        </div>
	);
};

export default Home;