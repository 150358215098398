import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./ExpertList.module.css";
import ExpertCell from "./ExpertCell";
import axios from 'axios';
import AddExpertModal from './AddExpertModal';

const ExpertList = (props) => {
    const navigate = useNavigate()
    const URL = "https://api.wisdomlsi.com/api/v1/admin/expert_list/"

    const [isAddExpertModalShow, setIsAddExpertModalShow] = useState(false);
    const [expertList, setExpertList] = useState([]);

	useEffect(() => {
        fetchExpertList();
	}, []);

    function fetchExpertList() {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setExpertList(response.data["expert"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function selectExpert(expert) {
        props.tabAction(expert);
    }

	return (
        <div className={styles.container}>
            <div className={styles.top_menu}>
                <p className={styles.num_expert}>전문가 {expertList && expertList.length}명</p>
                <p className={styles.add_expert} onClick={()=>setIsAddExpertModalShow(true)}>추가하기</p>
            </div>
            <div>
                {expertList && expertList.map((expert) => (
                    <ExpertCell key={expert.expert.id} expert={expert} selectAction={selectExpert} />
                ))}
            </div>
            { isAddExpertModalShow ? <AddExpertModal refreshAction={fetchExpertList} modalAction={setIsAddExpertModalShow} /> : null }
        </div>
	);
};

export default ExpertList;