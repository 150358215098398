import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import GroupStudentCell from './GroupStudentCell';
import styles from "./ConsultingPref.module.css"
import axios from 'axios';
import ConsultingCell from './ConsultingCell';

const ConsultingPref = (props) => {
    const navigate = useNavigate()
    const URL = "https://api.wisdomlsi.com/api/v1/admin/consulting_list/"

    const [consultingList, setConsultingList] = useState([]);

	useEffect(() => {
        fetchConsultingList();
	}, []);

    function fetchConsultingList() {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setConsultingList(response.data["consulting"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

	return (
        <div className={styles.container}>
            <div className={styles.top_menu}>
                <p className={styles.num_consulting}>상담요청 {consultingList && consultingList.length}개</p>
            </div>
            <div>
                {consultingList && consultingList.map((consulting) => (
                    <ConsultingCell key={consulting.info.id} consulting={consulting} />
                ))}
            </div>
        </div>
	);
};

export default ConsultingPref;