import React, { useState } from 'react';
import styles from "./Header.module.css"
import { useNavigate } from 'react-router-dom';

const Header = (props) => {
    const navigate = useNavigate();

	return (
		<div className={styles.header_top}>
			<div className={styles.brandGroup}>
				<img className={styles.logo} src={require('./wisdom_logo_small.png')}></img>
				<p className={styles.brand}>위즈덤센터</p>
			</div>
		</div>
	);
};

export default Header;