import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./AcceptGroupSignUpModal.module.css"
import axios from 'axios';

const AcceptGroupSignUpModal = (props) => {
	const ACCEPT_GROUP_URL = "https://api.wisdomlsi.com/api/v1/admin/accept_group/"
    const navigate = useNavigate();

	function acceptGroup() {
		const params = {
			"group_id": props.group.id
		}

		axios({
			method: 'post',
			url: ACCEPT_GROUP_URL,
            data: JSON.stringify(params),
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(res) {
			if (res.data["code"] === "success") {
				alert("그룹가입이 승인되었습니다.");
                props.refreshAction();
			}
		})
		.catch(function(err) {
			if (err.response.status == 401) {
				navigate('/');
			}
		});
	}

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>{props.group.name} 그룹의 가입요청을 승인할까요?</p> 
				<p className={styles.modal_desc}>그룹의 임시비밀번호는 'wisdom'으로 설정됩니다.<br />가입회원에게 비밀번호 변경을 공지하세요.</p>
                <button className={styles.accept_btn} onClick={acceptGroup}>승인하기</button>
                <button className={styles.cancel_btn} onClick={()=>props.modalControl(false)}>취소</button>
			</div>
		</div>
	);
};

export default AcceptGroupSignUpModal;