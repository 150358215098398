import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./ConsultingCell.module.css"
import axios from 'axios';

const ConsultingCell = (props) => {
    const navigate = useNavigate()

    function getCounselorText() {
        const counselor = props.consulting.info.counselor;

        if (counselor === "my_expert") {
            return "담당 전문가";
        } else if (counselor === "counselor_1") {
            return "1급 상담가";
        } else if (counselor === "counselor_2") {
            return "2급 상담가";
        } else if (counselor === "doc_lee") {
            return "이은주 박사님";
        } else {
            return "";
        }
    }

    function getMethodText() {
        const method = props.consulting.info.method;

        if (method=== "center") {
            return "센터 방문";
        } else if (method === "screen") {
            return "화상 상담";
        } else if (method === "phone") {
            return "전화 상담";
        } else {
            return "";
        }
    }

	return (
        <div>
            <div className={styles.container}>
                <div className={styles.group}>
                    <div className={styles.user_group}>
                        <p className={styles.title}>요청회원</p>
                        <p className={styles.name}>{props.consulting.student.name}</p>
                        <div className={styles.user_group_in}>
                            <p className={styles.email}>{props.consulting.email}</p>
                            <p className={styles.email}>{props.consulting.student.phone_number}</p>
                        </div>
                    </div>
                    <div className={styles.info_group}>
                        <div className={styles.info_group_in}>
                            <p className={styles.title}>희망 상담가</p>
                            <p className={styles.name_sub}>{getCounselorText()}</p>
                        </div>
                        <div className={styles.info_group_in}>
                            <p className={styles.title}>희망 상담방식</p>
                            <p className={styles.name_sub}>{getMethodText()}</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr className={styles.devider} />
        </div>
	);
};

export default ConsultingCell;