import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import GroupStudentCell from './GroupStudentCell';
import styles from "./ActiveGroupCell.module.css"
import Dropdown from 'react-dropdown';
import axios from 'axios';

const ActiveGroupCell = (props) => {
    const navigate = useNavigate()

	return (
        <div>
            <div className={styles.container}>
                <div className={styles.info}>
                    <div className={styles.info_out}>
                        <div className={styles.info_in}>
                            <p className={styles.name}>{props.group.info.name}</p>
                            <p className={styles.email}>{props.group.info.email}</p>
                        </div>
                        <p className={styles.phone}>{props.group.info.phone_number}</p>
                    </div>
                    <div className={styles.subinfo}>
                        <div className={styles.num_group}>
                            <div className={styles.num_group_in}>
                                <p className={styles.num_hint}>연결된 학생</p>
                                <p className={styles.num_data}>{props.group.num_student}명</p>
                            </div>
                            <div className={styles.num_group_in}>
                                <p className={styles.num_hint}>연결된 지도자</p>
                                <p className={styles.num_data}>{props.group.num_teacher}명</p>
                            </div>
                        </div>
                        <div className={styles.subinfo_in}>
                            <button className={styles.delete} onClick={() => props.deleteAction(props.group)}>삭제</button>
                        </div>
                    </div>
                </div>
            </div>
            <hr className={styles.devider} />
        </div>
	);
};

export default ActiveGroupCell;