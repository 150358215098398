import React from "react";
import styles from './GroupCell.module.css';

const GroupCell = (props) => {
    return (
        <div className={styles.parent} onClick={()=>props.selectAction(props.group)}>
            <div className={styles.main_box}>
                <p className={styles.name}>{props.group.info.name}</p>
                <p className={styles.numStudent}>학생 {props.group.num_student}명</p>
            </div>
            <p className={styles.email}>{props.group.info.email}</p>
            <p className={styles.phoneNumber}>{props.group.info.phone_number}</p>
            <hr className={styles.divider} />
        </div>
    );
}

export default GroupCell;