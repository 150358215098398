import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./SupervisorCell.module.css"
import axios from 'axios';

const SupervisorCell = (props) => {
    const navigate = useNavigate()

    function select() {
        props.selectAction(props.supervisor);
    }

	return (
        <div>
            <div className={styles.container} onClick={select}>
                <div className={styles.info}>
                    <div>
                        <div>
                            <p className={styles.name}>{props.supervisor.info.name}</p>
                            <p className={styles.email}>{props.supervisor.email}</p>
                        </div>
                        <p className={styles.email}>{props.supervisor.phone_number}</p>
                    </div>
                    <div>
                        <p className={styles.hint}>연결된 전문가</p>
                        <p className={styles.name_num}>{props.supervisor.num_expert}명</p>
                    </div>
                </div>
            </div>
            <hr className={styles.devider} />
        </div>
	);
};

export default SupervisorCell;