import React, { useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./ConnectStudentModal.module.css"
import axios from 'axios';
import StudentConnectCell from './StudentConnectCell';
import ExpertConnectCell from './ExpertConnectCell';

let expertIdList = [];

const ConnectExpertModal = (props) => {
    const EXPERT_URL = "https://api.wisdomlsi.com/api/v1/admin/expert_list_to_connect_with_supervisor/";
    const CONNECT_URL = "https://api.wisdomlsi.com/api/v1/admin/connect_supervisor_expert/";

    const navigate = useNavigate();
    const [expertList, setExpertList] = useState([]);

	useEffect(() => {
        fetchExpertList();
	}, []);

    function fetchExpertList() {
        const params = {
            "supervisor_id": props.supervisor.supervisor.id
        }

		axios({
			method: 'get',
			url: EXPERT_URL,
            params: params,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setExpertList(response.data["expert_list"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function connectExpert() {
        if (expertIdList.length === 0) {
            alert("연결하실 회원을 선택해주세요.");
        } else {
            const param = {
                "expert_id_list": expertIdList,
                "supervisor_id": props.supervisor.supervisor.id
            }

            axios({
                method: 'post',
                url: CONNECT_URL,
                data: JSON.stringify(param),
                headers: {
				    "Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                    "Content-Type" : "application/json; charset=utf-8"
                }
            })
            .then(function(response) {
                if (response.data["code"] === "success") {
                    expertIdList = [];
                    props.refreshAction();
                    props.modalControl();
                }
            })
            .catch(function(error) {
                console.log("");
                console.log("ERROR : " + JSON.stringify(error));
                console.log("");

                // if (error.response.status === 401) {
                //     alert("");
                //     navigate("/");
                // }
            });
        }
    }

    function selectExpert(expert) {
        let isSelected = false;
        let selectedIndex = -1;

        for (let i=0; i<expertIdList.length; i++) {
            if (expertIdList[i] === expert.expert.id) {
                isSelected = true;
                selectedIndex = i;
                break;
            }
        }

        if (isSelected) {
            expertIdList.splice(selectedIndex, 1);
        } else {
            expertIdList.push(expert.expert.id);
        }
    }

    function cancel() {
        expertIdList = [];
        props.modalControl();
    }

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>{props.supervisor.info.name} 슈퍼바이저님과<br/>연결할 전문가를 선택해주세요</p>
				<p className={styles.desc}>선택한 전문가가 다른 슈퍼바이저와 연결중이었다면<br/>해당 연결은 해제되고 현재 선택된 슈퍼바이저에게 새로 연결합니다</p>
                <div className={styles.scroll}>
                    {expertList && expertList.map((expert) => (
                        <ExpertConnectCell key={expert.expert.id} expert={expert} selectAction={selectExpert} />
                    ))}
                </div>
                <div className={styles.btn_group}>
                    <button className={styles.connect_btn} onClick={connectExpert}>연결하기</button>
                    <p className={styles.space}></p>
                    <button className={styles.cancel_btn} onClick={cancel}>취소</button>
                </div>
			</div>
		</div>
	);
};

export default ConnectExpertModal;