import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LogIn from './LogIn';
import Home from './Home';
import Expert from './Expert';
import Supervisor from './Supervisor';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LogIn />}></Route>
          <Route path="home/" element={<Home />}></Route>
          <Route path="expert/" element={<Expert />}></Route>
          <Route path="supervisor/" element={<Supervisor/>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
