import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./DisconnectExpertModal.module.css"
import axios from 'axios';

const DisconnectExpertModal = (props) => {
	const URL = "https://api.wisdomlsi.com/api/v1/admin/disconnect_supervisor_expert/"
    const navigate = useNavigate();

    console.log("!!");
    console.log(props);

	function disconnect() {
		const params = {
            "supervisor_id": props.supervisor.supervisor.id,
			"expert_id": props.expert.expert.id
		}

		axios({
			method: 'delete',
			url: URL,
            data: JSON.stringify(params),
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(res) {
			if (res.data["code"] === "success") {
                props.refreshAction();
                props.modalControl(false);
			}
		})
		.catch(function(err) {
			if (err.response.status == 401) {
				navigate('/');
			}
		});
	}

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>{props.expert.info.name}</p> 
				<p className={styles.modal_desc}>이 전문가를 해당 슈퍼바이저와 연결해제할까요?</p>
                <button className={styles.disconnect_btn} onClick={disconnect}>연결해제</button>
                <button className={styles.cancel_btn} onClick={()=>props.modalControl(false)}>취소</button>
			</div>
		</div>
	);
};

export default DisconnectExpertModal;