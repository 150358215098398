import React from 'react';
import styles from './ExpertConnectCell.module.css'
import { Link } from 'react-router-dom';

const ExpertConnectCell = (props) => {
	return (
		<div className={styles.container}>
            <div className={styles.title_group}>
                <div className={styles.info_group}>
                    <p className={styles.name}>{props.expert.info.name}</p>
                    <div className={styles.email_group}>
                        <p className={styles.email}>{props.expert.email}</p>
                        <p className={styles.email}>{props.expert.info.phone_number}</p>
                    </div>
                </div>
                <input className={styles.check_box} type="checkbox" onClick={()=>props.selectAction(props.expert)}></input>
            </div>
			<hr className={styles.devider} />
		</div>
	);
};

export default ExpertConnectCell;