import React from 'react';
import styles from './StudentConnectCell.module.css'
import { Link } from 'react-router-dom';

const StudentConnectCell = (props) => {
	return (
		<div className={styles.container}>
            <div className={styles.title_group}>
                <div className={styles.info_group}>
                    <p className={styles.name}>{props.student.student.name}</p>
                    <div className={styles.email_group}>
                        <p className={styles.email}>{props.student.email}</p>
                        <p className={styles.email}>{props.student.student.phone_number}</p>
                    </div>
                </div>
                <input className={styles.check_box} type="checkbox" onClick={()=>props.selectAction(props.student)}></input>
            </div>
			<hr className={styles.devider} />
		</div>
	);
};

export default StudentConnectCell;