import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./StudentCell.module.css"
import axios from 'axios';

const StudentCell = (props) => {
    const navigate = useNavigate()

	return (
        <div>
            <div className={styles.container}>
                <div className={styles.info}>
                    <div>
                        <div>
                            <p className={styles.name}>{props.student.student.name}</p>
                            <p className={styles.email}>{props.student.email}</p>
                        </div>
                        <p className={styles.email}>{props.student.student.phone_number}</p>
                    </div>
                    <button className={styles.disconnect} onClick={()=>props.disconnectAction(props.student)}>연결해제</button>
                </div>
            </div>
            <hr className={styles.devider} />
        </div>
	);
};

export default StudentCell;