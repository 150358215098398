import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./DisconnectStudentModal.module.css"
import axios from 'axios';

const DisconnectStudentModal = (props) => {
	const URL = "https://api.wisdomlsi.com/api/v1/admin/disconnect_expert_student/"
    const navigate = useNavigate();

	function disconnect() {
		const params = {
			"expert_id": props.expert.expert.id,
            "student_id": props.student.student.id
		}

		axios({
			method: 'delete',
			url: URL,
            data: JSON.stringify(params),
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(res) {
			if (res.data["code"] === "success") {
                props.refreshAction();
                props.modalControl(false);
			}
		})
		.catch(function(err) {
			if (err.response.status == 401) {
				navigate('/');
			}
		});
	}

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>{props.student.student.name}</p> 
				<p className={styles.modal_desc}>이 회원을 해당 전문가와 연결해제할까요?</p>
                <button className={styles.disconnect_btn} onClick={disconnect}>연결해제</button>
                <button className={styles.cancel_btn} onClick={()=>props.modalControl(false)}>취소</button>
			</div>
		</div>
	);
};

export default DisconnectStudentModal;