import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./CouponPref.module.css"
import axios from 'axios';
import CouponCell from './CouponCell';
import AddCouponModal from './AddCouponModal';

const CouponPref = (props) => {
    const navigate = useNavigate();
    const URL = "https://api.wisdomlsi.com/api/v1/admin/get_coupon_list/"

    const [couponList, setCouponList] = useState([]);
    const [isShowAddCouponModal, setIsShowAddCouponModal] = useState();

	useEffect(() => {
        fetchCouponList();
	}, []);

    function fetchCouponList() {
		axios({
			method: 'get',
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setCouponList(response.data["coupon_list"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

	return (
        <div className={styles.container}>
            <div className={styles.top_menu}>
                <p className={styles.title}>쿠폰 {couponList && couponList.length}개</p>
                <button className={styles.add_btn} onClick={()=>setIsShowAddCouponModal(!isShowAddCouponModal)}>쿠폰 발급</button>
            </div>
            <div>
                {couponList && couponList.map((coupon) => (
                    <CouponCell key={coupon.coupon.id} coupon={coupon} />
                ))}
            </div>
            { isShowAddCouponModal ? <AddCouponModal refreshAction={fetchCouponList} modalAction={()=>setIsShowAddCouponModal(false)} /> : null }
        </div>
	);
};

export default CouponPref;