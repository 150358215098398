import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./Expert.module.css"
import axios from 'axios';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import StudentCell from './StudentCell';
import DisconnectStudentModal from './DisconnectStudentModal';
import ConnectStudentModal from './ConnectStudentModal';
import DeleteExpertModal from './DeleteExpertModal';

let willDisconnectStudent = null;

const Expert = (props) => {
    const navigate = useNavigate()
    const URL = "https://api.wisdomlsi.com/api/v1/admin/expert_student_list/"

    const [isConnectModalShow, setIsConnectModalShow] = useState(false);
    const [isDisconnectModalShow, setIsDiconnectModalShow] = useState(false);
    const [isDeleteExpertModalShow, setIsDeleteExpertModalShow] = useState(false);
    const [studentList, setStudentList] = useState([]);

	useEffect(() => {
        fetchStudentList();
	}, []);

    function fetchStudentList() {
        const params = {
            "expert_id": props.expert.expert.id
        }

		axios({
			method: 'get',
            params: params,
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setStudentList(response.data["student_list"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function disconnectStudent(student) {
        willDisconnectStudent = student;
        setIsDiconnectModalShow(true);
    }

    function expertDeleted() {
        props.tabAction(1);
    }

	return (
        <div className={styles.container}>
            <div className={styles.top_menu}>
                <div className={styles.back_group} onClick={() => props.tabAction(1)}>
                    <ArrowBackIosIcon />
                    <p className={styles.title}>{props.expert.info.name}</p>
                </div>
                <div>
                    <button className={styles.connect} onClick={()=>setIsConnectModalShow(true)}>회원연결 추가</button>
                    <button className={styles.delete_expert} onClick={()=>setIsDeleteExpertModalShow(true)}>전문가 자격해제</button>
                </div>
            </div>
            <div>
                {studentList && studentList.map((student) => (
                    <StudentCell student={student} disconnectAction={disconnectStudent} />
                ))}
            </div>
            { studentList && studentList.length === 0 ?
                <p className={styles.empty_msg}>해당전문가와 연결된 회원이 없습니다.</p> : null
            }
            { isConnectModalShow ? <ConnectStudentModal expert={props.expert} refreshAction={fetchStudentList} modalControl={setIsConnectModalShow} /> : null }
            { isDisconnectModalShow ? <DisconnectStudentModal expert={props.expert} student={willDisconnectStudent} refreshAction={fetchStudentList} modalControl={setIsDiconnectModalShow} /> : null }
            { isDeleteExpertModalShow ? <DeleteExpertModal expert={props.expert} doneAction={expertDeleted} modalControl={setIsDeleteExpertModalShow} /> : null }
        </div>
	);
};

export default Expert;