import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from "./AddExpertModal.module.css"
import axios from 'axios';

const AddSupervisorModal = (props) => {
    const emailRef = useRef("");
    const URL = "https://api.wisdomlsi.com/api/v1/admin/add_supervisor/";

    const navigate = useNavigate();

    function addExpert() {
        if (validate()) {
            const param = {
                "email": emailRef.current.value
            }

            axios({
                method: 'post',
                url: URL,
                data: JSON.stringify(param),
                headers: {
				    "Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                    "Content-Type" : "application/json; charset=utf-8"
                }
            })
            .then(function(response) {
                console.log(response.data);
                
                if (response.data["code"] === "success") {
                    props.refreshAction();
                    props.modalAction();
                } else if (response.data["code"] === "error") {
                    alert(response.data["message"]);
                }
            })
            .catch(function(error) {
                console.log("");
                console.log("ERROR : " + JSON.stringify(error));
                console.log("");

                // if (error.response.status === 401) {
                //     alert("");
                //     navigate("/");
                // }
            });
        }
    }

    function validate() {
        if (emailRef.current.value === "") {
            alert("이메일을 입력해주세요.");
            return false;
        } else {
            return true;
        }
    }

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>이메일을 입력해주세요</p>
				<p className={styles.modal_desc}>추가된 슈퍼바이저는<br />로그인 즉시 활동할 수 있습니다</p>
                <div className={styles.feedback_group}>
                    <input placeholder="이메일" className={styles.email} ref={emailRef}></input>
                    <div>
                        <button className={styles.add_btn} onClick={addExpert}>추가하기</button>
                        <button className={styles.cancel_btn} onClick={()=>props.modalAction()}>취소</button>
                    </div>
                </div>
			</div>
		</div>
	);
};

export default AddSupervisorModal;