import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./DeleteGroupModal.module.css"
import axios from 'axios';

const DeleteGroupModal = (props) => {
	const DELETE_GROUP_URL = "https://api.wisdomlsi.com/api/v1/admin/delete_group/"
    const navigate = useNavigate();

    const [group, setGroup] = useState(null);

	useEffect(() => {
        initGroup();
	}, []);

    function initGroup() {
        if (props.group.info === undefined) {
            setGroup(props.group);
        } else {
            setGroup(props.group.info);
        }
    }

	function deleteGroup() {
		const params = {
			"group_id": group.id
		}

		axios({
			method: 'delete',
			url: DELETE_GROUP_URL,
            data: JSON.stringify(params),
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(res) {
			if (res.data["code"] === "success") {
				alert("그룹 정보가 삭제되었습니다.");
                props.refreshAction();
			}
		})
		.catch(function(err) {
			if (err.response.status == 401) {
				navigate('/');
			}
		});
	}

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
                { group && group.status === "active" ?
				    <p className={styles.modal_title}>{group && group.name} 그룹을 영구적으로 삭제할까요?</p> :
				    <p className={styles.modal_title}>{group && group.name} 그룹의 가입요청을 삭제할까요?</p> 
                }
				<p className={styles.modal_desc}>그룹과 관련된 정보는 모두 삭제되며<br />절대 되돌릴 수 없습니다.</p>
                <button className={styles.delete_btn} onClick={deleteGroup}>삭제하기</button>
                <button className={styles.cancel_btn} onClick={()=>props.modalControl(false)}>취소</button>
			</div>
		</div>
	);
};

export default DeleteGroupModal;