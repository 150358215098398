import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from "./AddCouponModal.module.css"
import axios from 'axios';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

let nowTestClassCode = "wpi1";
let nowTestClassText = "WPI현실";

const AddCouponModal = (props) => {
    const URL = "https://api.wisdomlsi.com/api/v1/admin/add_coupon/";

    const emailRef = useRef("");
    const numCouponRef = useRef("");
    const [testClassCode, setTestClassCode] = useState("wpi1");

    const navigate = useNavigate();

	const testClassCodeList = [
		{ value: "wpi1", label: 'WPI현실' }, 
		{ value: "wpi2", label: 'WPI이상' }, 
		{ value: "study", label: "LSI공부" }, 
		{ value: "exercise", label: "LSI운동" }];

    function addCoupon() {
        if (validate()) {
            const param = {
                "group_email": emailRef.current.value,
                "num_coupon": numCouponRef.current.value,
                "test_class_code": nowTestClassCode
            }

            axios({
                method: 'post',
                url: URL,
                data: JSON.stringify(param),
                headers: {
				    "Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
                    "Content-Type" : "application/json; charset=utf-8"
                }
            })
            .then(function(response) {
                console.log(response.data);
                
                if (response.data["code"] === "success") {
                    nowTestClassCode = "wpi1";
                    nowTestClassText = "WPI현실";

                    props.refreshAction();
                    props.modalAction();
                } else if (response.data["code"] === "error") {
                    alert(response.data["message"]);
                }
            })
            .catch(function(error) {
                console.log("");
                console.log("ERROR : " + JSON.stringify(error));
                console.log("");

                // if (error.response.status === 401) {
                //     alert("");
                //     navigate("/");
                // }
            });
        }
    }

    function selectOption(option) {
        nowTestClassCode = option.value;
        nowTestClassText = option.label;
        setTestClassCode(option.value);
    }

    function validate() {
        if (emailRef.current.value === "") {
            alert("이메일을 입력해주세요.");
            return false;
        } else if (numCouponRef.current.value === "") {
            alert("쿠폰 개수를 입력해주세요.");
            return false;
        } else {
            return true;
        }
    }

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>쿠폰발급을 위해<br/>아래정보를 입력해주세요</p>
                <div className={styles.feedback_group}>
                    <input placeholder="그룹 이메일" className={styles.email} ref={emailRef}></input>
                    <Dropdown className={styles.group_option} onChange={value => selectOption(value)} options={testClassCodeList} value={nowTestClassText} />
                    <input type="number" placeholder="몇개를 발급할까요?" className={styles.email} ref={numCouponRef}></input>
                    <div>
                        <button className={styles.add_btn} onClick={addCoupon}>발급하기</button>
                        <button className={styles.cancel_btn} onClick={()=>props.modalAction()}>취소</button>
                    </div>
                </div>
			</div>
		</div>
	);
};

export default AddCouponModal;