import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./RequestGroupCell.module.css"
import axios from 'axios';

const RequestGroupCell = (props) => {
    const navigate = useNavigate()

	return (
        <div>
            <div className={styles.container}>
                <div className={styles.info}>
                    <div className={styles.info_out}>
                        <div className={styles.info_in}>
                            <p className={styles.name}>{props.group.name}</p>
                            <p className={styles.email}>{props.group.email}</p>
                        </div>
                        <div className={styles.info_group_out}>
                            <div className={styles.info_group}>
                                <p className={styles.info_title}>전화번호</p>
                                <p className={styles.phone}>{props.group.phone_number}</p>
                            </div>
                            <div className={styles.info_group}>
                                <p className={styles.info_title}>주소</p>
                                <p className={styles.phone}>{props.group.address}</p>
                            </div>
                            <div className={styles.info_group}>
                                <p className={styles.info_title}>지도과목</p>
                                <p className={styles.phone}>{props.group.subjects}</p>
                            </div>
                            <div className={styles.info_group}>
                                <p className={styles.info_title}>정원</p>
                                <p className={styles.phone}>{props.group.num_people}명</p>
                            </div>
                            <div className={styles.info_group}>
                                <p className={styles.info_title}>메모</p>
                                <p className={styles.phone}>{props.group.memo}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.btn_group}>
                        <button className={styles.accept} onClick={() => props.acceptAction(props.group)}>가입승인</button>
                        <button className={styles.delete} onClick={() => props.deleteAction(props.group)}>삭제</button>
                    </div>
                </div>
            </div>
            <hr className={styles.devider} />
        </div>
	);
};

export default RequestGroupCell;