import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./Supervisor.module.css"
import axios from 'axios';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import StudentCell from './StudentCell';
import ConnectExpertModal from './ConnectExpertModal';
import DisconnectExpertModal from './DisconnectExpertModal';
import DeleteSupervisorModal from './DeleteSupervisorModal';
import SupervisorExpertCell from './SupervisorExpertCell';

let willDisconnectExpert = null;

const Supervisor = (props) => {
    const navigate = useNavigate()
    const URL = "https://api.wisdomlsi.com/api/v1/admin/supervisor_expert_list/"

    const [isConnectModalShow, setIsConnectModalShow] = useState(false);
    const [isDisconnectModalShow, setIsDiconnectModalShow] = useState(false);
    const [isDeleteSupervisorModalShow, setIsDeleteSupervisorModalShow] = useState(false);
    const [expertList, setExpertList] = useState([]);

	useEffect(() => {
        fetchExpertList();
	}, []);

    function fetchExpertList() {
        const params = {
            "supervisor_id": props.supervisor.supervisor.id
        }

		axios({
			method: 'get',
            params: params,
			url: URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setExpertList(response.data["expert_list"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function disconnectExpert(expert) {
        willDisconnectExpert = expert;
        setIsDiconnectModalShow(true);
    }

    function supervisorDeleted() {
        props.tabAction(2);
    }

	return (
        <div className={styles.container}>
            <div className={styles.top_menu}>
                <div className={styles.back_group} onClick={() => props.tabAction(2)}>
                    <ArrowBackIosIcon />
                    <p className={styles.title}>{props.supervisor.info.name}</p>
                </div>
                <div>
                    <button className={styles.connect} onClick={()=>setIsConnectModalShow(true)}>전문가연결 추가</button>
                    <button className={styles.delete_expert} onClick={()=>setIsDeleteSupervisorModalShow(true)}>슈퍼바이저 자격해제</button>
                </div>
            </div>
            <div>
                {expertList && expertList.map((expert) => (
                    <SupervisorExpertCell key={expert.expert.id} expert={expert} disconnectAction={disconnectExpert} />
                ))}
            </div>
            { expertList && expertList.length === 0 ?
                <p className={styles.empty_msg}>해당전문가와 연결된 회원이 없습니다.</p> : null
            }
            { isConnectModalShow ? <ConnectExpertModal supervisor={props.supervisor} refreshAction={fetchExpertList} modalControl={setIsConnectModalShow} /> : null }
            { isDisconnectModalShow ? <DisconnectExpertModal supervisor={props.supervisor} expert={willDisconnectExpert} refreshAction={fetchExpertList} modalControl={setIsDiconnectModalShow} /> : null }
            { isDeleteSupervisorModalShow ? <DeleteSupervisorModal supervisor={props.supervisor} doneAction={supervisorDeleted} modalControl={setIsDeleteSupervisorModalShow} /> : null }
        </div>
	);
};

export default Supervisor;