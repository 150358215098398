import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from "./ExpertCell.module.css"
import axios from 'axios';

const ExpertCell = (props) => {
    const navigate = useNavigate()

    function select() {
        props.selectAction(props.expert);
    }

	return (
        <div>
            <div className={styles.container} onClick={select}>
                <div className={styles.info}>
                    <div>
                        <div>
                            <p className={styles.name}>{props.expert.info.name}</p>
                            <p className={styles.email}>{props.expert.email}</p>
                        </div>
                        <p className={styles.email}>{props.expert.info.phone_number}</p>
                    </div>
                    <div>
                        <p className={styles.hint}>연결된 회원</p>
                        <p className={styles.name_num}>{props.expert.num_student}명</p>
                    </div>
                </div>
            </div>
            <hr className={styles.devider} />
        </div>
	);
};

export default ExpertCell;