import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./DisconnectStudentModal.module.css"
import axios from 'axios';

const DeleteExpertModal = (props) => {
	const URL = "https://api.wisdomlsi.com/api/v1/admin/delete_expert/"
    const navigate = useNavigate();

	function deleteExpert() {
		const params = {
			"expert_id": props.expert.expert.id
		}

		axios({
			method: 'delete',
			url: URL,
            data: JSON.stringify(params),
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(res) {
			if (res.data["code"] === "success") {
                props.doneAction();
                props.modalControl(false);
			}
		})
		.catch(function(err) {
			if (err.response.status == 401) {
				navigate('/');
			}
		});
	}

	return (
		<div className={styles.container}>
			<div className={styles.container_inside}>
				<p className={styles.modal_title}>{props.expert.info.name}</p> 
				<p className={styles.modal_desc}>이 회원의 전문가 자격을 해제할까요?<br/>연결되었던 회원은 모두 연결해제됩니다.</p>
                <button className={styles.disconnect_btn} onClick={deleteExpert}>자격해제</button>
                <button className={styles.cancel_btn} onClick={()=>props.modalControl(false)}>취소</button>
			</div>
		</div>
	);
};

export default DeleteExpertModal;