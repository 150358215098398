import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styles from "./LogIn.module.css"
import axios from 'axios';

const LogIn = (props) => {
    const URL = "https://api.wisdomlsi.com/api/v1/login_admin/";

    const navigate = useNavigate();

    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    function logIn() {
        if (!validate()) { return; }

        const param = {
            "email": emailRef.current.value,
            "password": passwordRef.current.value
        }

        axios({
            method: 'post',
            url: URL,
            data: JSON.stringify(param),
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            }
        })
        .then(function(response) {
            console.log(response.data);
            let code = response.data["code"];

            if (code === "error") {
                alert(response.data["message"]);
            } else {
                const data = response.data["data"];
                console.log(data);

                const accessToken = data["token"]["access"];
                const refreshToken = data["token"]["refresh"];

                window.localStorage.setItem("wisdom_access", accessToken);
                window.localStorage.setItem("wisdom_refresh", refreshToken);

                navigate("home/");
            }
        })
        .catch(function(error) {
            console.log(error);
        });
    }

    function validate() {
        if (emailRef.current.value === "") {
            return false;
        } else if (passwordRef.current.value === "") {
            return false;
        } else {
            return true;
        }
    }

	return (
        <div className={styles.parent}>
            <div className={styles.top}>
                <p className={styles.title_1}>위즈덤센터</p>
                <p className={styles.title_2}>관리자 로그인</p>
                <div className={styles.input_group}>
                    <input type={"text"} className={styles.input_box} placeholder="계정이름" ref={emailRef}></input>
                    <input type={"password"} className={styles.input_box} placeholder="비밀번호" ref={passwordRef}></input>
                </div>
                <button className={styles.login} onClick={logIn}>로그인</button>
            </div>
        </div>
	);
};

export default LogIn;