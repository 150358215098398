import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ActiveGroupCell from './ActiveGroupCell';
import RequestGroupCell from './RequestGroupCell';
import styles from "./GroupList.module.css"
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import axios from 'axios';
import DeleteGroupModal from './DeleteGroupModal';
import AcceptGroupSignUpModal from './AcceptGroupSignUpModal';

let nowOptionCode = "registered";
let nowOptionText = "등록된 그룹";
let willBeAcceptGroup = null;
let willBeDeleteGroup = null;

const GroupList = (props) => {
    const GROUP_URL = "https://api.wisdomlsi.com/api/v1/admin/group_list/"

    const navigate = useNavigate()

    const [isAcceptGroupModalShow, setIsAcceptGroupModalShow] = useState(false);
    const [isDeleteGroupModalShow, setIsDeleteGroupModalShow] = useState(false);
    const [optionCode, setOptionCode] = useState("registered");
    const [activeGroupList, setActiveGroupList] = useState([]);
    const [requestGroupList, setRequestGroupList] = useState([]);

	const groupOptionList = [
		{ value: "registered", label: '등록된 그룹목록' }, 
		{ value: "requested", label: "가입요청 그룹목록" }];

	useEffect(() => {
        fetchGroupList();
	}, []);

    function fetchGroupList() {
		axios({
			method: 'get',
			url: GROUP_URL,
			headers: {
				"Authorization": "Bearer " + window.localStorage.getItem("wisdom_access"),
				"Content-Type": "application/json; charset=utf-8"
			}
		})
		.then(function(response) {
            console.log(response.data);
            setActiveGroupList(response.data["data"]["active_group_list"]);
            setRequestGroupList(response.data["data"]["request_group_list"]);
		})
		.catch(function(error) {
			if (error.response.status == 401) {
				navigate('/');
			}
		});
    }

    function acceptGroup(group) {
        console.log(group);
        willBeAcceptGroup = group;
        setIsAcceptGroupModalShow(true);
    }

    function deleteGroup(group) {
        console.log(group);
        willBeDeleteGroup = group;
        setIsDeleteGroupModalShow(true);
    }

    function selectOption(option) {
        nowOptionCode = option.value;
        nowOptionText = option.label;
        setOptionCode(option.value);
    }

    function refreshGroupListFromModal() {
        setOptionCode(nowOptionCode);
        setIsAcceptGroupModalShow(false);
        setIsDeleteGroupModalShow(false);
        fetchGroupList();
    }


    // const showInviteModal = () => {
    //     if (isInviteModalShow) {
    //         fetchStudentList();
    //     }

    //     return (
    //         setIsInviteModalShow(!isInviteModalShow)
    //     )
    // }

    // const showDeleteUserModal = () => {
    //     if (isDeleteUserModalShow) {
    //         fetchStudentList();
    //     }
        
    //     return (
    //         setDeleteUserModalShow(!isDeleteUserModalShow)
    //     )
    // }

	return (
        <div className={styles.container}>
            <div className={styles.top_menu}>
                <Dropdown className={styles.group_option} onChange={value => selectOption(value)} options={groupOptionList} value={nowOptionText} />
            </div>
            { optionCode === "registered" ?
                <div>
                    { activeGroupList.length === 0 ?
                        <p className={styles.empty_msg}>그룹이 없습니다</p> :
                        <div>
                            {activeGroupList && activeGroupList.map((group) => (
                                <ActiveGroupCell key={group.id} group={group} deleteAction={deleteGroup} />
                            ))}
                        </div>
                    }
                </div> :
                <div>
                    { requestGroupList.length === 0 ? 
                        <p className={styles.empty_msg}>그룹이 없습니다</p> :
                        <div>
                            {requestGroupList && requestGroupList.map((group) => (
                                <RequestGroupCell key={group.id} group={group} acceptAction={acceptGroup} deleteAction={deleteGroup} />
                            ))}
                        </div>
                    }
                </div>
            }
            { isAcceptGroupModalShow ? <AcceptGroupSignUpModal group={willBeAcceptGroup} modalControl={setIsAcceptGroupModalShow} refreshAction={refreshGroupListFromModal} /> : null }
            { isDeleteGroupModalShow ? <DeleteGroupModal group={willBeDeleteGroup} modalControl={setIsDeleteGroupModalShow} refreshAction={refreshGroupListFromModal} /> : null }
        </div>
	);
};

export default GroupList;